import {
  PipelinesApi,
  PipelineStatusType,
} from 'dips-pipeline-service-client-v2';
import { useApiConfig } from './useApiConfig';
import { useQuery } from 'react-query';

interface usePipelinesQueryProp {
  index: number;
  size: number;
  reverse?: boolean;
  filter?: PipelineStatusType[];
  id?: string[] | undefined;
  start?: string;
  end?: string;
}

function useAPIsQuery({
  index,
  size,
  reverse,
  filter,
  id,
  start,
  end,
}: usePipelinesQueryProp) {
  const { getApiConfig, axiosInstance } = useApiConfig();

  const apis = useQuery(
    ['testPipelines', index, size, reverse, filter, id, start, end],
    async () =>
      getApiConfig()
        .then(
          (apiConfig) => new PipelinesApi(apiConfig, undefined, axiosInstance),
        )
        .then((api) =>
          api.searchPipelines({
            pageIndex: index,
            pageSize: size,
            reverse: reverse,
            statuses: filter,
            ids: id,
            dateTimeFrom: start,
            dateTimeTo: end,
          }),
        )
        .then((response) => response.data),
    {
      refetchOnWindowFocus: false,
    },
  );

  return apis;
}

export default useAPIsQuery;
