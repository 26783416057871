import React from 'react';
import { Breadcrumb, Button } from 'antd';

interface MyBreadcrumbProps {
  imagePath?: string[];
}

const PathBreadcrumb: React.FC<MyBreadcrumbProps> = ({ imagePath }) => {
  const breadcrumbStyle = {
    backgroundColor: 'rgb(245, 245, 245)',
    padding: '5px',
    borderRadius: '4px',
    color: 'black',
  };

  const linkStyle = {
    color: 'black',
    textDecoration: 'none',
  };

  return (
    <div style={breadcrumbStyle}>
      <Breadcrumb>
        {imagePath?.map((path, index) => (
          <Breadcrumb.Item key={index}>
            <a href="#" style={linkStyle}>
              {path}
            </a>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default PathBreadcrumb;
