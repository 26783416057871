import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Auth0Provider, withAuthenticationRequired } from '@auth0/auth0-react';
import config from './config';

const AuthenticatedApp = withAuthenticationRequired(App);
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      authorizationParams={{
        redirect_uri: config.redirectUri,
        audience: config.audience,
        scope:
          'read:environments read:jobs read:pipelines openid: email openid:name',
        connection: config.connection,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthenticatedApp />
          <ReactQueryDevtools />
        </BrowserRouter>
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
