import {
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Input,
  Space,
  Table,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import usePipelinesQuery from '../../hooks/usePipelinesQuery';
import {
  Job,
  Pipeline,
  PipelineSearchResultPage,
  PipelineStatus,
  PipelineStatusType,
} from 'dips-pipeline-service-client-v2';
import { TableColumnsType } from 'antd';
import { StatusIcon } from '../../utils/StatusIcon';
import { DateTime } from 'luxon';
import '../../styling/Table.css';
import JobsDrawer from '../jobs/JobsDrawer';
import CopyButton from '../../utils/IdCopyPair';
import { PipelineStatusLabel } from '../pipelines/PipelineStatusLabel';
import Datadog from './Datadog';
import useMediaQuery from '../../hooks/useMediaQuery';
import { SiDatadog } from 'react-icons/si';
import { FilterFilled, RightOutlined } from '@ant-design/icons';
import PipelineJson from '../pipelines/PipelineJson';
const { RangePicker } = DatePicker;

function TableData() {
  const [pgIndex, setPgIndex] = useState<number>(0);
  const [pgSize, setPgSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rev, setRev] = useState<boolean>(false);
  const [filter, setFilter] = useState<PipelineStatusType[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job>();
  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline>();
  const [searchText, setSearchText] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const { data, isLoading, refetch } = usePipelinesQuery({
    index: pgIndex,
    size: pgSize,
    reverse: rev,
    filter: filter,
    id: searchText.length > 0 ? searchText : undefined,
    start: startDate,
    end: endDate,
  });

  const [columnWidths, setColumnWidths] = useState<
    {
      id: number;
      status: number;
      date: number;
      correlation: number;
      job: number;
    }[]
  >([]);

  const getColumnWidth = async (data: PipelineSearchResultPage) => {
    if (!data || !data.pipelines) {
      return [];
    }

    return data.pipelines.map((item) => ({
      id: item.id.length * 5,
      status: item.status.type.length * 20,
      date: item.createdAt.length * 5,
      correlation: item.correlationId.length * 5,
      job: item.jobs.length,
    }));
  };

  useEffect(() => {
    const calculateWidths = async () => {
      if (data) {
        const widths = await getColumnWidth(data);
        setColumnWidths(widths);
      }
    };

    calculateWidths();
  }, [data]);
  const sortData = () => {
    setRev((prev) => !prev);
  };

  useEffect(() => {
    refetch();
  }, [rev]);

  const filterData = (checkedValues: PipelineStatusType[]) => {
    setFilter(checkedValues);
  };
  useEffect(() => {
    refetch();
  }, [filter]);

  const timeAgo = (dateString: string) => {
    const date = DateTime.fromISO(dateString, { zone: 'utc' });
    const now = DateTime.utc();
    const diff = now.diff(date);

    const days = Math.floor(diff.as('days'));
    const hours = Math.floor(diff.as('hours')) % 24;
    const minutes = Math.floor(diff.as('minutes')) % 60;

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return date.toRelative({ base: DateTime.utc() });
    }
  };
  const timeAgoShort = (dateString: string) => {
    const date = DateTime.fromISO(dateString, { zone: 'utc' });
    const now = DateTime.utc();
    const diff = now.diff(date);

    const days = Math.floor(diff.as('days'));
    const hours = Math.floor(diff.as('hours')) % 24;
    const minutes = Math.floor(diff.as('minutes')) % 60;

    if (days > 0) {
      return `${days} d`;
    } else if (hours > 0) {
      return `${hours} h`;
    } else if (minutes > 0) {
      return `${minutes} m`;
    } else {
      return 'now';
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPgIndex(0);
    refetch();
    const value = e.target.value;
    const terms = value ? value.split(',').map((term) => term.trim()) : [];
    setSearchText(terms);
  };
  const largescreen = useMediaQuery('(min-width:1880px)');
  const lessThan = useMediaQuery('(min-width:1510px)');
  const matches = useMediaQuery('(min-width:1464px)');
  const smallerMatch = useMediaQuery('(min-width:1358px)');
  const leastMatch = useMediaQuery('(min-width:800px)');

  const handleDateChange = (dates: any | null) => {
    if (dates) {
      const [start, end] = dates;

      refetch();

      const endPlusOneDay = end.add(1, 'day');
      const endFormatted =
        endPlusOneDay.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

      setStartDate(start.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z');
      setEndDate(endFormatted);
    } else {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  };

  const columns: TableColumnsType<Pipeline> = [
    {
      title: (
        <center>
          {smallerMatch && <span>Status</span>}
          <Dropdown
            menu={{
              items: [
                {
                  key: 'filter',
                  label: (
                    <Checkbox.Group onChange={filterData}>
                      <Checkbox value={PipelineStatusType.Error}>
                        Error
                      </Checkbox>
                      <Checkbox value={PipelineStatusType.Idle}>Idle</Checkbox>
                      <Checkbox value={PipelineStatusType.Pending}>
                        Pending
                      </Checkbox>
                      <Checkbox value={PipelineStatusType.Running}>
                        Running
                      </Checkbox>
                      <Checkbox value={PipelineStatusType.Success}>
                        Success
                      </Checkbox>
                      <Checkbox value={PipelineStatusType.Warning}>
                        Warning
                      </Checkbox>
                    </Checkbox.Group>
                  ),
                },
              ],
            }}
          >
            <Tooltip title="Filter based on status">
              <Button type="link">
                <FilterFilled style={{ color: 'rgb(42 42 42 / 54%);' }} />
              </Button>
            </Tooltip>
          </Dropdown>
        </center>
      ),
      dataIndex: 'status',
      width: matches
        ? Math.max(...columnWidths.map((width) => width.status)) + 20
        : '8%',
      onFilter: (value, record: Pipeline) => {
        return filter.includes(record.status.type as PipelineStatusType);
      },
      render: (status: PipelineStatus) => (
        <center>
          <PipelineStatusLabel
            type={status.type}
            showLabel={matches}
            errorMessage={status.errorMessage}
          ></PipelineStatusLabel>
        </center>
      ),
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {smallerMatch && <span style={{ marginRight: 8 }}>ID</span>}

          <Input
            placeholder="Search by ID"
            value={searchText.join(', ')}
            onChange={handleInputChange}
            allowClear={true}
            style={{
              marginLeft: 8,
              width: largescreen ? '250px' : smallerMatch ? '160px' : '50px',
            }}
          />
        </div>
      ),

      dataIndex: 'id',
      key: 'pipelineid',
      width: matches ? '20%' : smallerMatch ? '15%' : '10%',
      render: (pipeId: string) => {
        const len = pipeId.length;
        return (
          <center>
            <div style={{ display: 'inline-block', cursor: 'pointer' }}>
              <CopyButton
                text={pipeId}
                length={len}
                copy={smallerMatch}
                placeholder={
                  largescreen
                    ? pipeId.substring(0, 23) + '...'
                    : lessThan
                      ? pipeId.substring(0, 8) +
                        '....' +
                        pipeId.substring(len - 4, len)
                      : matches
                        ? pipeId.substring(0, 10) + '...'
                        : pipeId.substring(0, 3) + '...'
                }
              ></CopyButton>
            </div>
          </center>
        );
      },
    },

    {
      title: (
        <>
          <Tooltip
            title={`Click to sort in ${
              !rev ? 'descending' : 'ascending'
            } order`}
          >
            {leastMatch ? <span>Creation Date</span> : <span>Date</span>}
          </Tooltip>
        </>
      ),
      dataIndex: 'createdAt',
      sorter: true,
      showSorterTooltip: false,
      width: largescreen ? '10%' : matches ? '15%' : '10%',
      filterDropdown: ({ close }) => (
        <div style={{ padding: 8 }}>
          <Space direction="vertical">
            <RangePicker
              allowClear={true}
              onChange={(dates) => {
                handleDateChange(dates);
                close();
              }}
              format="YYYY-MM-DD"
            />
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <>
          <Tooltip title="Filter based on date">
            <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
          </Tooltip>
        </>
      ),

      onHeaderCell: () => ({
        onClick: sortData,
      }),

      render: (createdAt: string) => (
        <center>
          <Tooltip
            title={
              new Date(createdAt).toLocaleString('en-US', { timeZone: 'UTC' }) +
              '(UTC)'
            }
          >
            <span>
              {leastMatch ? timeAgo(createdAt) : timeAgoShort(createdAt)}
            </span>
          </Tooltip>
        </center>
      ),
    },
    {
      title: (
        <>{leastMatch ? <span>Correlation Id</span> : <span>Corr ID</span>}</>
      ),
      dataIndex: 'correlationId',
      width: matches ? '20%' : '15%',
      render: (correlationId: string, text) => {
        const len = correlationId.length;

        return (
          <center>
            <div style={{ display: 'inline-block', cursor: 'pointer' }}>
              <CopyButton
                text={correlationId}
                copy={smallerMatch}
                placeholder={
                  largescreen
                    ? correlationId.substring(0, 23) + '...'
                    : lessThan
                      ? correlationId.substring(0, 8) +
                        '....' +
                        correlationId.substring(len - 4, len)
                      : matches
                        ? correlationId.substring(0, 10) + '...'
                        : correlationId.substring(0, 3) + '...'
                }
                length={len}
              ></CopyButton>
            </div>
          </center>
        );
      },
    },
    {
      title: (
        <>
          {smallerMatch ? (
            <span>Datadog</span>
          ) : (
            <SiDatadog size={'25px'}></SiDatadog>
          )}
        </>
      ),
      key: 'datadog',
      width: largescreen ? '12%' : '10%',
      render: (text, record, index) => (
        <center>
          <Datadog
            id={text.correlationId}
            created={text.createdAt}
            copy={leastMatch}
          ></Datadog>
        </center>
      ),
    },
    {
      title: 'Job Status',
      dataIndex: 'jobs',
      key: 'status',
      width: '20%',
      render: (jobs, text, record) => {
        const handleDrawer = (job: Job) => {
          setSelectedJob(job);
        };
        return (
          <center>
            <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
              {jobs.map((job: Job) => (
                <Button
                  type="link"
                  onClick={() => handleDrawer(job)}
                  style={{ width: '1px' }}
                >
                  {' '}
                  <StatusIcon type={job.status.type} showLabel={false} />
                </Button>
              ))}
            </div>
          </center>
        );
      },
    },
    {
      title: 'JSON',
      key: 'json',
      width: '8%',
      render: (text, record, index) => {
        const handleJsonDrawer = (pipeline: Pipeline) => {
          setSelectedPipeline(pipeline);
        };
        return (
          <center>
            <Button onClick={() => handleJsonDrawer(record)}>
              <RightOutlined />
            </Button>
          </center>
        );
      },
    },
  ];

  return (
    <div style={{ padding: '2px 1px 1px 1px', fontSize: '160%' }}>
      <div>
        <span
          style={{
            fontSize: '18px',
            marginLeft: '2%',
            fontWeight: '500',
            margin: smallerMatch
              ? '1px 8% 2px 8%'
              : matches
                ? '1px 2% 2px 2%'
                : '1px 5% 2px 5%',
          }}
        >
          Total Number of Pipelines: {data?.totalCount}
        </span>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={data?.pipelines || []}
          loading={isLoading}
          sticky
          scroll={{ x: 1000 }}
          pagination={{
            defaultCurrent: 0,
            defaultPageSize: pgSize,
            current: currentPage,
            total: data?.totalCount,
            position: ['topRight', 'bottomRight'],
            style: { marginTop: '1px' },
            onChange: (page, pgSize) => {
              setCurrentPage(page);
              setPgIndex(page - 1);
              setPgSize(pgSize);
            },

            showSizeChanger: true,
          }}
          style={{
            fontSize: '150%',
            padding: largescreen
              ? '1px 4% 2px 4%'
              : smallerMatch
                ? '1px 8% 2px 8%'
                : matches
                  ? '1px 1% 2px 1%'
                  : '1px 10% 2px 10%',

            marginBottom: '2px',
            backgroundColor: 'transparent !important',
          }}
        />

        <JobsDrawer
          job={selectedJob}
          unSetJob={() => setSelectedJob(undefined)}
        />
        <PipelineJson
          pipeline={selectedPipeline}
          unSetPipeline={() => setSelectedPipeline(undefined)}
        />
      </div>
    </div>
  );
}

export default TableData;
