import { Drawer, Radio, RadioChangeEvent, Space } from 'antd';
import { Job } from 'dips-pipeline-service-client-v2';
import React, { useState } from 'react';
import '../../styling/RawButton.css';
import CopyTextButton from '../../utils/CopyTextButton';

interface JobProps {
  job?: Job;
  unSetJob?: () => void;
}

const JsonDrawer: React.FC<JobProps> = ({ job, unSetJob }) => {
  const [raw, setRaw] = useState<boolean>(false);
  const handleChange = (e: RadioChangeEvent) => {
    if (e.target.value === 'raw') {
      setRaw(true);
    } else setRaw(false);
  };

  return (
    <div>
      <Drawer
        title="JSON"
        placement="left"
        width={600}
        closable={true}
        onClose={unSetJob}
        open={!!job}
        mask={true}
        maskClosable={true}
        keyboard={true}
      >
        <div>
          <Space>
            <Radio.Group onChange={handleChange}>
              <Radio.Button value="raw">Raw</Radio.Button>

              <Radio.Button
                value="pretty"
                style={{ backgroundColor: 'rgb(157 106 161)', color: 'white' }}
              >
                Pretty
              </Radio.Button>
            </Radio.Group>
            <CopyTextButton
              text={JSON.stringify(job, null, 2)}
            ></CopyTextButton>
          </Space>
        </div>
        {raw ? (
          <>
            <pre>{JSON.stringify(job)}</pre>
          </>
        ) : (
          <pre style={{ fontFamily: 'Consolas, monospace' }}>
            {JSON.stringify(job, null, 2)}
          </pre>
        )}

        <br></br>
      </Drawer>
    </div>
  );
};

export default JsonDrawer;
