import { Configuration } from 'dips-pipeline-service-client-v2';
import axios from 'axios';

import config from '../config';
import { useAuth0 } from '@auth0/auth0-react';

export const useApiConfig = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const getApiConfig = async () => {
    if (!isAuthenticated) {
      throw new Error('User is not authenticated');
    }

    const bearerToken = await getAccessTokenSilently();

    return new Configuration({
      basePath: config.basePath,
      baseOptions: {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      },
    });
  };

  return { getApiConfig, axiosInstance: axios };
};
