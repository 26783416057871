import { Button, message } from 'antd';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaRegCopy } from 'react-icons/fa6';

interface CopyProps {
  text: string;
}
const CopyTextButton: React.FC<CopyProps> = ({ text }) => {
  const handleCopy = () => {
    message.success('Copied to clipboard!');
  };
  return (
    <div>
      <CopyToClipboard text={text} onCopy={handleCopy}>
        <Button
          value="copy"
          style={{
            backgroundColor: 'rgb(157 106 161)',
            color: 'white',
            marginLeft: '8px',
          }}
        >
          <FaRegCopy />
        </Button>
      </CopyToClipboard>
    </div>
  );
};

export default CopyTextButton;
