import React from 'react';
import { Tag, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  Loading3QuartersOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { JobStatusType, JobError } from 'dips-pipeline-service-client-v2';

interface StatusProps {
  type?: JobStatusType;
  showLabel?: boolean;
  error?: JobError;
  errorMessage?: string;
}

export const StatusIcon: React.FC<StatusProps> = ({
  type,
  showLabel = false,
  errorMessage,
}) => {
  switch (type) {
    case JobStatusType.Idle:
      return (
        <>
          {showLabel ? (
            <Tag icon={<MinusCircleOutlined />} color="default">
              {JobStatusType.Idle}
            </Tag>
          ) : (
            <Tooltip title={JobStatusType.Idle}>
              <Tag icon={<MinusCircleOutlined />} color="default"></Tag>
            </Tooltip>
          )}
        </>
      );
    case JobStatusType.Success:
      return (
        <>
          {showLabel ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              {JobStatusType.Success}
            </Tag>
          ) : (
            <Tooltip title={JobStatusType.Success}>
              <Tag icon={<CheckCircleOutlined />} color="success"></Tag>
            </Tooltip>
          )}
        </>
      );

    case JobStatusType.Running:
      return (
        <>
          {showLabel ? (
            <Tag icon={<SyncOutlined spin />} color="processing">
              {JobStatusType.Running}
            </Tag>
          ) : (
            <Tooltip title={JobStatusType.Running}>
              <Tag icon={<SyncOutlined spin />} color="processing" />
            </Tooltip>
          )}
        </>
      );
    case JobStatusType.Warning:
      return (
        <>
          {showLabel ? (
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              {JobStatusType.Warning}
            </Tag>
          ) : (
            <Tooltip title={JobStatusType.Warning}>
              <Tag icon={<ExclamationCircleOutlined />} color="warning" />
            </Tooltip>
          )}
        </>
      );
    case JobStatusType.Error:
      if (JobError.DockerApiError) {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {JobError.DockerApiError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      } else if (JobError.DockerContainerError) {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {JobError.DockerContainerError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      } else if (JobError.DockerImageNotFound) {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {JobError.DockerImageNotFound}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      } else if (JobError.PipelineTimeoutError) {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {JobError.PipelineTimeoutError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      } else if (JobError.SecretRetrievalError) {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {JobError.SecretRetrievalError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      } else if (JobError.TimeoutError) {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {JobError.TimeoutError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      } else if (JobError.UnknownError) {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {JobError.UnknownError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      } else {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {JobError.UnknownError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title={JobStatusType.Error}>
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      }

    case JobStatusType.Preparing:
      return (
        <>
          {showLabel ? (
            <Tag icon={<Loading3QuartersOutlined />} color="cyan">
              {JobStatusType.Preparing}
            </Tag>
          ) : (
            <Tooltip title={JobStatusType.Preparing}>
              <Tag icon={<Loading3QuartersOutlined />} color="cyan" />
            </Tooltip>
          )}
        </>
      );
    case JobStatusType.Downloading:
      return (
        <>
          {showLabel ? (
            <Tag icon={<DownloadOutlined />} color="geekblue">
              {JobStatusType.Downloading}
            </Tag>
          ) : (
            <Tooltip title={JobStatusType.Downloading}>
              <Tag icon={<DownloadOutlined />} color="geekblue" />
            </Tooltip>
          )}
        </>
      );
    default:
      return <div>Status not found</div>;
  }
};
