import { Button, message, Space, Tooltip } from 'antd';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FaLink } from 'react-icons/fa6';
import { FaExternalLinkAlt } from 'react-icons/fa';
import useMediaQuery from '../../hooks/useMediaQuery';

interface CopyProps {
  id: string;
  created: string;
  copy?: boolean;
}

const Datadog: React.FC<CopyProps> = ({ id, created, copy }) => {
  const handleCopy = () => {
    message.success('Copied to clipboard!');
  };

  const urlCorID =
    'https://syk-jr-dev.datadoghq.com/logs?query=%40CorrelationId%3A';
  const urlCorID2 =
    '%20project%3Adips2%20&agg_m=count&agg_m_source=base&agg_t=count&cols=host%2Cservice&event=AgAAAZHGOct1cdPc_wAAAAAAAAAYAAAAAEFaSEdPZEFaQUFEXzdoMTJMNGgzd1FBQgAAACQAAAAAMDE5MWM2MzktZTE0MS00NzY1LTg1OGMtNzI1NjllZTU4ZGM0&fromUser=true&messageDisplay=inline&refresh_mode=sliding&storage=hot&stream_sort=time%2Cdesc&view=spans&viz=stream&from_ts=';

  const url3 = '&to_ts=';

  const url4 = '&live=true';
  const currentTime = () => {
    return Math.floor(Date.now() / 1000).toString() + '000';
  };
  const getTimestampMinutesBefore = (dateString: string, minutes: number) => {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() - minutes);
    return Math.floor(date.getTime() / 1000).toString() + '000';
  };
  const link =
    urlCorID +
    id +
    urlCorID2 +
    getTimestampMinutesBefore(created, 60) +
    url3 +
    currentTime() +
    url4;

  const wide = useMediaQuery('(min-width:2400px)');
  return (
    <div>
      <Space.Compact style={{ width: '100%', display: 'inline-block' }}>
        <>
          <Tooltip title={'Go to datadog'}>
            <a
              href={link}
              style={{ color: 'black' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {wide ? (
                <Button
                  style={{
                    border: 'solid rgb(127, 42, 134) 1px',
                    color: 'black',
                    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2)',
                    backgroundColor: 'rgb(157, 106, 161, 0.08)',
                  }}
                >
                  Go to datadog
                </Button>
              ) : (
                <FaExternalLinkAlt
                  style={{
                    fontSize: '15px',
                    marginLeft: '20px',
                    marginRight: '20px',
                  }}
                />
              )}
            </a>
          </Tooltip>
          {copy && (
            <Tooltip title={'Copy datadog link'}>
              <CopyToClipboard text={link} onCopy={handleCopy}>
                {wide ? (
                  <Button
                    style={{
                      backgroundColor: 'rgb(157 106 161)',
                      color: 'white',
                      boxShadow: 'none !important',
                    }}
                  >
                    Copy
                  </Button>
                ) : (
                  <FaLink style={{ fontSize: '16px', border: 'black' }} />
                )}
              </CopyToClipboard>
            </Tooltip>
          )}
        </>
      </Space.Compact>
    </div>
  );
};

export default Datadog;
