import { Drawer, Radio, RadioChangeEvent, Space } from 'antd';
import { Pipeline } from 'dips-pipeline-service-client-v2';
import React, { useState } from 'react';
import CopyTextButton from '../../utils/CopyTextButton';

interface PipeProps {
  pipeline?: Pipeline;
  unSetPipeline?: () => void;
}

const PipelineJson: React.FC<PipeProps> = ({ pipeline, unSetPipeline }) => {
  const [raw, setRaw] = useState<boolean>(false);
  const handleChange = (e: RadioChangeEvent) => {
    if (e.target.value === 'raw') {
      setRaw(true);
    } else setRaw(false);
  };
  return (
    <div>
      <Drawer
        title="JSON"
        placement="left"
        width={800}
        closable={true}
        onClose={unSetPipeline}
        open={!!pipeline}
        mask={true}
        maskClosable={true}
        keyboard={true}
      >
        <div>
          <Space>
            <Radio.Group onChange={handleChange}>
              <Radio.Button value="raw">Raw</Radio.Button>

              <Radio.Button
                value="pretty"
                style={{ backgroundColor: 'rgb(157 106 161)', color: 'white' }}
              >
                Pretty
              </Radio.Button>
            </Radio.Group>
            <CopyTextButton
              text={JSON.stringify(pipeline, null, 2)}
            ></CopyTextButton>
          </Space>
        </div>
        {raw ? (
          <>
            <pre>{JSON.stringify(pipeline)}</pre>
          </>
        ) : (
          <pre style={{ fontFamily: 'monospace' }}>
            {JSON.stringify(pipeline, null, 2)}
          </pre>
        )}

        <br></br>
      </Drawer>
    </div>
  );
};

export default PipelineJson;
