import { message, Space, Tooltip, Radio, RadioChangeEvent } from 'antd';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import '../styling/IdCopyPair.css';
import { FaRegCopy } from 'react-icons/fa6';
import useMediaQuery from '../hooks/useMediaQuery';

interface CopyProps {
  text: string;
  placeholder?: string;
  length?: number;
  copy?: boolean;
}
const IdCopyPair: React.FC<CopyProps> = ({
  text,
  placeholder,
  length,
  copy,
}) => {
  const handleCopy = () => {
    message.success('Copied to clipboard!');
  };
  const [selectedValue, setSelectedValue] = useState<string>('end');

  const handleChange = (e: RadioChangeEvent) => {
    if (e.target.value === 'id') {
      return;
    }
    setSelectedValue(e.target.value);
  };
  const lessThan = useMediaQuery('(min-width:1510px)');
  const matches = useMediaQuery('(min-width:1464px)');
  const largescreen = useMediaQuery('(min-width:1880px)');

  return (
    <div>
      <Space>
        <Radio.Group onChange={handleChange} value={selectedValue}>
          {copy && (
            <Tooltip title={text}>
              <Radio.Button
                value="id"
                style={{
                  color: 'black',
                  cursor: 'pointer',
                  width: largescreen
                    ? '240px'
                    : lessThan
                      ? '155px'
                      : matches
                        ? '150px'
                        : '100px',
                }}
              >
                {placeholder}
              </Radio.Button>
            </Tooltip>
          )}

          <CopyToClipboard text={text} onCopy={handleCopy}>
            <Radio.Button
              value="copy"
              style={{
                backgroundColor: 'rgb(157 106 161)',
                color: 'white',
                boxShadow: 'none !important',
              }}
            >
              {largescreen ? 'Copy' : <FaRegCopy />}
            </Radio.Button>
          </CopyToClipboard>
        </Radio.Group>
      </Space>
    </div>
  );
};

export default IdCopyPair;
