type Config = {
  bearerToken: string;
  redirectUri: string;
  auth0ClientId: string;
  auth0Domain: string;
  basePath: string;
  audience: string;
  connection: string;
  gitlabClientId: string;
  gitlabScope: string;
};

const config: Config = (window as any).config;

export default config;
