import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Radio,
  Row,
  Tag,
  Tooltip,
} from 'antd';
import {
  Job,
  JobStatusType,
  Pipeline,
  JobStatus,
} from 'dips-pipeline-service-client-v2';
import { StatusIcon } from '../../utils/StatusIcon';
import useMediaQuery from '../../hooks/useMediaQuery';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import JsonDrawer from './JsonDrawer';
import PathBreadcrumb from '../../utils/PathBreadCrumb';
import CopyTextButton from '../../utils/CopyTextButton';
import '../../styling/DrawerJobs.css';

interface DescriptionItemProps {
  title: string | React.ReactNode;
  content: React.ReactNode;
}
interface JobProps {
  job?: Job;
  unSetJob: () => void;
}

const JobsDrawer: React.FC<JobProps> = ({ job, unSetJob }) => {
  const [selectedJob, setSelectedJob] = useState<Job>();
  const leastMatch = useMediaQuery('(min-width:800px)');

  const handleDrawer = (job: Job) => {
    setSelectedJob(job);
  };

  const convertSecondsToMinutes = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minute${
      minutes !== 1 ? 's' : ''
    } ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
  };

  const timeAgo = (dateString: string): number => {
    const date = new Date(dateString);
    return Date.now() - date.getTime();
  };
  let previousChange: number | undefined;

  const timeDiff = job?.statusChanges.map(
    (change: JobStatus, index: number, arr: JobStatus[]) => {
      const timestamp = new Date(change.changedAt).getTime();
      const nextChange = arr[index + 1];
      const diff = nextChange
        ? new Date(nextChange.changedAt).getTime() - timestamp
        : timeAgo(change.changedAt);
      previousChange = timestamp;
      return {
        diff,
        type: change.type,
        origin: change.changedAt,
        rawDiff: diff,
      };
    },
  );
  const formatDuration = (ms: number): string => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days `;
    } else if (hours > 0) {
      return `${hours} hr${hours > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      return `${minutes} min${minutes > 1 ? 's' : ''}`;
    } else {
      return `${seconds} sec${seconds > 1 ? 's' : ''}`;
    }
  };

  const imagePath = job?.image.split('/');
  const entryPath = job?.entrypoint?.split('/');
  return (
    <>
      <Drawer
        width={700}
        placement="left"
        closable={true}
        onClose={unSetJob}
        open={!!job}
        mask={true}
        maskClosable={true}
        keyboard={true}
      >
        <h2
          className="site-description-item-profile-p"
          style={{ marginBottom: 24 }}
        >
          Job details
          <Button
            value="copy"
            onClick={() => job && handleDrawer(job)}
            style={{
              backgroundColor: 'rgb(157 106 161)',
              color: 'white',
              marginLeft: '4px',
            }}
          >
            JSON
          </Button>
        </h2>

        <Row gutter={[16, 16]} style={{ display: 'flex' }}>
          <Col span={12}>
            <Card
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Job ID</span>
                  <CopyTextButton text={job?.id || ''}></CopyTextButton>
                </div>
              }
              bordered={true}
              hoverable={true}
              style={{ height: '100%' }}
            >
              {
                <>
                  <Radio.Button
                    value="id"
                    style={{
                      color: 'black',
                      cursor: 'default',
                    }}
                  >
                    {leastMatch ? job?.id : job?.id.substring(0, 3) + '...'}
                  </Radio.Button>
                </>
              }
            </Card>
          </Col>
          <Col span={12}>
            <Card
              hoverable={true}
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Job Image</span>
                  <CopyTextButton text={job?.image || ''}></CopyTextButton>
                </div>
              }
              bordered={true}
            >
              {
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <PathBreadcrumb imagePath={imagePath}></PathBreadcrumb>
                </span>
              }
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="Job Status"
              bordered={true}
              hoverable={true}
              style={{ height: '100%' }}
            >
              {
                <StatusIcon
                  type={job?.status.type}
                  showLabel={true}
                  errorMessage={job?.status.errorMessage}
                />
              }
            </Card>
          </Col>
          <Col span={12}>
            <Card
              hoverable={true}
              title="Allow Failure"
              bordered={true}
              style={{ height: '100%' }}
            >
              {job?.allowFailure ? (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  TRUE
                </Tag>
              ) : (
                <Tag icon={<CloseCircleOutlined />} color="error">
                  FALSE
                </Tag>
              )}
            </Card>
          </Col>

          <Col span={12}>
            <Card
              hoverable={true}
              title="Status Changes"
              bordered={true}
              style={{ height: '100%' }}
            >
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {timeDiff?.map((item, index) => (
                  <li key={index} style={{ paddingBottom: '4px' }}>
                    <Row gutter={20}>
                      <Col span={24}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ flex: '0 0 100px' }}>
                            {' '}
                            {/* Fixed width for alignment */}
                            {index === timeDiff.length - 1
                              ? `${formatDuration(item.rawDiff)} ago : `
                              : `For ${formatDuration(item.rawDiff)} : `}
                          </span>
                          <Tooltip
                            title={
                              new Date(item.origin).toLocaleString('en-US', {
                                timeZone: 'UTC',
                              }) + ' (UTC)'
                            }
                            placement="right"
                          >
                            <span style={{ marginLeft: '10px' }}>
                              <StatusIcon
                                type={item.type}
                                showLabel={leastMatch}
                              />
                            </span>
                          </Tooltip>
                        </div>
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            </Card>
          </Col>

          <Col span={12}>
            <Card
              hoverable={true}
              style={{ height: '100%' }}
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Job Command</span>
                  <CopyTextButton text={job?.command || ''}></CopyTextButton>
                </div>
              }
              bordered={true}
            >
              {
                <pre
                  style={{
                    backgroundColor: '#f5f5f5',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    padding: '10px',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    overflow: 'auto',
                  }}
                >
                  <code style={{ color: '#d14', fontFamily: 'monospace' }}>
                    {job?.command}
                  </code>
                </pre>
              }
            </Card>
          </Col>
          <Col span={12}>
            <Card
              hoverable={true}
              title="Entry Point"
              bordered={true}
              style={{ height: '100%' }}
            >
              {job?.entrypoint !== undefined ? (
                <>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <PathBreadcrumb imagePath={entryPath}></PathBreadcrumb>
                  </span>
                  <br />
                </>
              ) : (
                <em>No entry point set.</em>
              )}
            </Card>
          </Col>

          <Col span={12}>
            <Card
              hoverable={true}
              title="Success Exit Codes"
              bordered={true}
              style={{ height: '100%' }}
            >
              {job?.successExitCodes}
            </Card>
          </Col>
          <Col span={12}>
            <Card
              hoverable={true}
              title="Timeout"
              bordered={true}
              style={{ height: '100%' }}
            >
              {job?.timeoutInSeconds !== undefined ? (
                <>
                  {convertSecondsToMinutes(job.timeoutInSeconds)}
                  <br />
                </>
              ) : (
                <em>
                  No timeout set. The job's runtime is only limited by the
                  pipeline's timeout.
                </em>
              )}
            </Card>
          </Col>

          <Col span={12}>
            <Card
              hoverable={true}
              title="Mac Address"
              bordered={true}
              style={{ height: '100%' }}
            >
              {job?.macAddress !== undefined ? (
                <>
                  {job.macAddress}
                  <br />
                </>
              ) : (
                <em>No mac address set.</em>
              )}
            </Card>
          </Col>
        </Row>

        <Divider />
        {(job?.status.type == JobStatusType.Error ||
          job?.status.type == JobStatusType.Warning) && (
          <>
            <h2 style={{ color: '#2e0204' }}>Error Details</h2>
            <Row gutter={[16, 16]} style={{ display: 'flex' }}>
              <Col span={12}>
                <Card
                  title="Error Message"
                  bordered={true}
                  hoverable={true}
                  style={{ height: '100%', color: '#d14' }}
                >
                  {job?.status.errorMessage}
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  hoverable={true}
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>Error</span>
                      <CopyTextButton
                        text={job?.status.error || ''}
                      ></CopyTextButton>
                    </div>
                  }
                  bordered={true}
                  style={{ height: '100%' }}
                >
                  <pre
                    style={{
                      backgroundColor: '#f5f5f5',
                      border: '1px solid #d9d9d9',
                      borderRadius: '4px',
                      padding: '10px',
                      whiteSpace: 'pre-wrap',
                      wordBreak: 'break-word',
                      overflow: 'auto',
                    }}
                  >
                    <code style={{ color: '#d14', fontFamily: 'monospace' }}>
                      {job?.status.error}
                    </code>
                  </pre>
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  hoverable={true}
                  title="Exit Code"
                  bordered={true}
                  style={{ height: '100%' }}
                >
                  <span style={{ color: '#d14' }}>{job?.status.exitCode}</span>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Drawer>
      <JsonDrawer
        job={selectedJob}
        unSetJob={() => setSelectedJob(undefined)}
      />
    </>
  );
};

export default JobsDrawer;
