import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'osep-react-antd';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      type="primary"
      block
      onClick={() =>
        logout({
          logoutParams: { returnTo: window.location.origin, federated: true },
        })
      }
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
