import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import config from '../config';

interface VersionData {
  [serviceName: string]: string;
}
const serviceUrl = config.basePath;
function useServiceVersion() {
  return useQuery<VersionData, AxiosError>(
    ['service-versions', serviceUrl],
    () =>
      axios
        .get(`${serviceUrl}/versions`)
        .then((response) => response.data)
        .catch((error: AxiosError) => {
          if (error.response) {
            return error.response.data;
          }
          throw new Error('An unexpected error occurred');
        }),
    {
      retry: (failureCount, error: AxiosError) => {
        if (
          error.response?.status &&
          error.response.status >= 500 &&
          error.response.status < 600
        ) {
          return true;
        }
        return false;
      },
    },
  );
}

export default useServiceVersion;
