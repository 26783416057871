import React from 'react';
import { Tag, Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  PipelineStatusType,
  PipelineError,
} from 'dips-pipeline-service-client-v2';

interface StatusProps {
  type?: PipelineStatusType;
  showLabel?: boolean;
  error?: PipelineError;
  errorMessage?: string;
}

export const PipelineStatusLabel: React.FC<StatusProps> = ({
  type,
  showLabel = false,
  errorMessage,
}) => {
  switch (type) {
    case PipelineStatusType.Idle:
      return (
        <>
          {showLabel ? (
            <Tag icon={<MinusCircleOutlined />} color="default">
              IDLE
            </Tag>
          ) : (
            <Tooltip title="IDLE">
              <Tag icon={<MinusCircleOutlined />} color="default"></Tag>
            </Tooltip>
          )}
        </>
      );
    case PipelineStatusType.Success:
      return (
        <>
          {showLabel ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              SUCCESS
            </Tag>
          ) : (
            <Tooltip title="SUCCESS">
              <Tag icon={<CheckCircleOutlined />} color="success"></Tag>
            </Tooltip>
          )}
        </>
      );
    case PipelineStatusType.Pending:
      return (
        <>
          {showLabel ? (
            <Tag icon={<ClockCircleOutlined />} color="default">
              PENDING
            </Tag>
          ) : (
            <Tooltip title="PENDING">
              <Tag icon={<CheckCircleOutlined />} color="success" />
            </Tooltip>
          )}
        </>
      );
    case PipelineStatusType.Running:
      return (
        <>
          {showLabel ? (
            <Tag icon={<SyncOutlined spin />} color="processing">
              RUNNING
            </Tag>
          ) : (
            <Tooltip title="RUNNING">
              <Tag icon={<SyncOutlined spin />} color="processing" />
            </Tooltip>
          )}
        </>
      );
    case PipelineStatusType.Warning:
      return (
        <>
          {showLabel ? (
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              WARNING
            </Tag>
          ) : (
            <Tooltip title="WARNING">
              <Tag icon={<ExclamationCircleOutlined />} color="warning" />
            </Tooltip>
          )}
        </>
      );
    case PipelineStatusType.Error:
      if (PipelineError.JobError) {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {PipelineError.JobError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      } else if (PipelineError.TimeoutError) {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {PipelineError.TimeoutError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      } else {
        return (
          <>
            {showLabel ? (
              <Tooltip title={errorMessage}>
                <Tag icon={<CloseCircleOutlined />} color="error">
                  {PipelineError.UnknownError}
                </Tag>
              </Tooltip>
            ) : (
              <Tooltip title="ERROR">
                <Tag icon={<CloseCircleOutlined />} color="error" />
              </Tooltip>
            )}
          </>
        );
      }

    default:
      return <Tag color="magenta">Status not found</Tag>;
  }
};
