import { Layout } from 'osep-react-antd';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from './LogoutButton';
import TableData from './TableData';
import { Tag } from 'antd';
import { VERSION } from '../../version';
import useServiceVersion from '../../hooks/useServiceVersion';

function HomePage() {
  const { data, error, isLoading, isError } = useServiceVersion();
  const { user } = useAuth0();
  const nameParts = user?.email?.split('.');
  const displayName = nameParts
    ? nameParts.length > 1
      ? nameParts[0].trim()
      : nameParts[1]
    : 'User';

  return (
    <div>
      <Layout
        appName="DIPS UI"
        username={displayName}
        additionalFooterInfos={[
          <span key="version" className="inline-block">
            {VERSION}
          </span>,
        ]}
        popoverUserContent={
          <div>
            <p style={{ margin: 0 }}>Logged in as</p>
            <p style={{ marginTop: 0, fontWeight: 'bold' }}>{user?.email}</p>
            <p>
              Pipeline service version:
              <Tag
                bordered={false}
                style={{ marginLeft: '2px' }}
                color="magenta"
              >
                {data && data['dips-pipeline-service']}
                {error && error.message}
              </Tag>
            </p>
            <LogoutButton />
          </div>
        }
      >
        <TableData></TableData>
      </Layout>
    </div>
  );
}

export default HomePage;
